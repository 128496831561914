import merge from "deepmerge";
import {green, grey, indigo, red} from "@mui/material/colors";
import {THEMES} from "../constants";

const customPurple = {
    50: "#ffffff",
    100: "#DDBFFF",
    200: "#D1AAFE",
    300: "#C898FF",
    400: "#BC87F9",
    500: "#B677FE",
    600: "#A556FF",
    700: "#8A24FF",
    // 800: "#7D63EA",
    800: "#8A24FF",
    900: "#5400B4",
};

const defaultVariant = {
    name: THEMES.DEFAULT,
    palette: {
        mode: "light",
        primary: {
            main: customPurple[800],
            contrastText: "#FFF",
        },
        secondary: {
            main: "#39B4EA",
            contrastText: "#FFF",
        },
        background: {
            default: "#FCF9FF",
            paper: "#FFF",
        },
    },
    header: {
        color: grey[500],
        background: "#FFF",
        search: {
            color: grey[800],
        },
        indicator: {
            background: customPurple[600],
        },
    },
    footer: {
        color: "#414564",
        background: "#FFF",
    },
    sidebar: {
        color: grey[200],
        background: "#080809",
        header: {
            color: grey[200],
            background: "#FFFFFF",
            brand: {
                color: customPurple[800],
            },
        },
        footer: {
            color: grey[200],
            background: "#414564",
            online: {
                background: green[500],
            },
        },
        badge: {
            color: "#FFF",
            background: customPurple[800],
        },
    },
};

const darkVariant = merge(defaultVariant, {
    name: THEMES.DARK,
    palette: {
        mode: "dark",
        primary: {
            main: customPurple[600],
            contrastText: "#FFF",
        },
        background: {
            default: "#1B2635",
            paper: "#233044",
        },
        text: {
            primary: "rgba(255, 255, 255, 0.95)",
            secondary: "rgba(255, 255, 255, 0.5)",
        },
    },
    header: {
        color: grey[300],
        background: "#1B2635",
        search: {
            color: grey[200],
        },
    },
    footer: {
        color: grey[300],
        background: "#233044",
    },
});

const lightVariant = merge(defaultVariant, {
    name: THEMES.LIGHT,
    palette: {
        mode: "light",
    },
    header: {
        color: grey[200],
        background: customPurple[800],
        search: {
            color: grey[100],
        },
        indicator: {
            background: red[700],
        },
    },
    sidebar: {
        color: grey[900],
        background: "#FFF",
        header: {
            color: "#FFF",
            background: customPurple[800],
            brand: {
                color: "#FFFFFF",
            },
        },
        footer: {
            color: grey[800],
            background: "#F7F7F7",
            online: {
                background: green[500],
            },
        },
    },
});

const blueVariant = merge(defaultVariant, {
    name: THEMES.BLUE,
    palette: {
        mode: "light",
    },
    sidebar: {
        color: "#FFF",
        background: customPurple[700],
        header: {
            color: "#FFF",
            background: customPurple[800],
            brand: {
                color: "#FFFFFF",
            },
        },
        footer: {
            color: "#FFF",
            background: customPurple[800],
            online: {
                background: "#FFF",
            },
        },
        badge: {
            color: "#000",
            background: "#FFF",
        },
    },
});

const greenVariant = merge(defaultVariant, {
    name: THEMES.GREEN,
    palette: {
        primary: {
            main: green[800],
            contrastText: "#FFF",
        },
        secondary: {
            main: green[500],
            contrastText: "#FFF",
        },
    },
    header: {
        indicator: {
            background: green[600],
        },
    },
    sidebar: {
        color: "#FFF",
        background: green[700],
        header: {
            color: "#FFF",
            background: green[800],
            brand: {
                color: "#FFFFFF",
            },
        },
        footer: {
            color: "#FFF",
            background: green[800],
            online: {
                background: "#FFF",
            },
        },
        badge: {
            color: "#000",
            background: "#FFF",
        },
    },
});

const indigoVariant = merge(defaultVariant, {
    name: THEMES.INDIGO,
    palette: {
        primary: {
            main: indigo[600],
            contrastText: "#FFF",
        },
        secondary: {
            main: indigo[400],
            contrastText: "#FFF",
        },
    },
    header: {
        indicator: {
            background: indigo[600],
        },
    },
    sidebar: {
        color: "#FFF",
        background: indigo[700],
        header: {
            color: "#FFF",
            background: indigo[800],
            brand: {
                color: "#FFFFFF",
            },
        },
        footer: {
            color: "#FFF",
            background: indigo[800],
            online: {
                background: "#FFF",
            },
        },
        badge: {
            color: "#000",
            background: "#FFF",
        },
    },
});

const variants = [
    defaultVariant,
    darkVariant,
    lightVariant,
    blueVariant,
    greenVariant,
    indigoVariant,
];

export default variants;
export {customPurple};
