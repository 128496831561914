// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

// API BASE URL
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const token = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzdXBlcl9hZG1pbkBkaXNsLmNvbSIsImlhdCI6MTY2MjM3MTAwOSwiZXhwIjoxNjYyNDU3NDA5fQ.Qf-TFPX_9vdJtDwQOLi_-YVYZrmlybaoPR1mZfbOw7DwyFVavgWt0o1G7ax_ZeiM9dhrG38SXTMAhucWSUSNZw"

// eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzdXBlcl9hZG1pbkBkaXNsLmNvbSIsImlhdCI6MTY2MTY2MTA1OCwiZXhwIjoxNjYxNzQ3NDU4fQ.F0nnsm6S86GQFnEzrQcnE2J09ztpGiab1gMTvnLHzaZ-Q6Ip74YrFfo83I4-3TWlfsbVehnhWK7MWWFMaPlycw




//DEFY URL
export const DEFY_URL = "https://web.defy.dhrubok.xyz";

export const privilegeTags = [
  "BLOG",
  "INDUSTRY",
  "PRODUCT",
  "PAGE",
  "SECTION",
  "ROLE",
  "ADMIN",
  "AGENT",
  "TESTIMONIAL",
  "CARRIER",
  "NEWSLETTER",
  "SUPPORT_TICKET",
  "GENERAL",
  "USER",
  "LOG",
  "INSURANCE_TYPE",
];
