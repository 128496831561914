import axios from "axios";
import {BASE_URL} from "../constants";
import {isValidToken, setSession} from "../utils/jwt";
import {createContext, useEffect, useReducer, useState} from "react";
import { useSearchParams } from "react-router-dom";
import {
    getUserFromAPI
} from "../api/support";
import {useNavigate} from "react-router-dom";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";


// let loggedInUser = {
//     userId: 60,
//     id: 60,
//     userAuthId: 80,
//     email: "arbree01@gmail.com",
//     postalCode: 234,
//     name: "Mahabuba Islam",
//     country: "BD",
//     city: "Dhaka",
//     phone: "01829677689",
//     website: "test",
//     companyName: "Yellow",
//     companyType: null,
//     companyAddress: "Abc",
//     imageFileId: 1641385308694,
//     imageUrl: "https://prwire360.s3.amazonaws.com/staging/1641385308694.jpeg",
//     userType: "JOURNALIST",

//     userId: 57,
//     id: 57,
//     userAuthId: 78,
//     email: "super_admin@disl.com",
//     postalCode: 234,
//     name: "Super Islam",
//     country: "BD",
//     city: "Dhaka",
//     phone: "01829677689",
//     website: "test",
//     companyName: "Yellow",
//     companyType: null,
//     companyAddress: "Abc",
//     imageFileId: 1641385308694,
//     imageUrl: "https://prwire360.s3.amazonaws.com/staging/1641385308694.jpeg",
//     userType: "ADMIN",


//     availableNow: true
//     city: ""
//     companyLogoFileId: 0
//     companyLogoUrl: null
//     country: ""
//     createdBy: "SYSTEM"
//     creationDate: "18-07-2021 03:25:35"
//     email: "SUPER ADMIN"
//     id: 57
//     imageFileId: 1641377684764
//     imageUrl: "https://prwire360.s3.amazonaws.com/staging/1641379551307.jpeg"
//     isBanned: false
//     isVerified: true
//     lastModifiedBy: "SYSTEM"
//     lastModifiedDate: "18-03-2022 04:54:44"
//     name: "Super Admin"
//     superAdmin: true
//     supportTicketAgent: false
//     userAuthId: 78
//     userType: "ADMIN"
// }





let loggedInUser = null


export const initialState = {
    user: null,
    isInitialized: false,
    isAuthenticated: false,
};

export const JWTReducer = (state, action) => {
    switch (action.type) {
        case INITIALIZE:
            return {
                isInitialized: true,
                user: action.payload.user,
                isAuthenticated: action.payload.isAuthenticated,
            };
        case SIGN_IN:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
            };
        case SIGN_OUT:
            return {
                ...state,
                user: null,
                isAuthenticated: false,
            };

        case SIGN_UP:
            return {
                ...state,
                isAuthenticated: false,
                user: action.payload.user,
            };

        default:
            return state;
    }
};

const AuthContext = createContext(null);



const getUser = (key) => {
    const userToken = key
    getUserFromAPI(userToken)
    .then( (res)=> {
        if(res.data.payload){
            loggedInUser = res.data.payload
        }
    })
}

function AuthProvider({children}) {
    const [state, dispatch] = useReducer(JWTReducer, initialState);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()

    let userToken = searchParams.get("userToken")
    window.localStorage.setItem("userLocalToken", userToken === null ? window.localStorage.getItem("userLocalToken") : userToken );

    useEffect(() => {
        const initialize = async () => {
            try {
            
                // const accessToken = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzdXBlcl9hZG1pbkBkaXNsLmNvbSIsImlhdCI6MTY2MjQ2Mjc2OSwiZXhwIjoxNjYyNTQ5MTY5fQ.umejEuFrW9cvkj6EIyOrdYEWFNMwRDM6H2Z0C7WsHIHJLa7AGyYyauiMF1LjThhFCdp421MUD3fzI-U9W4kv3g";
                const accessToken = "apiHeader@prwire360"
                const tokenOnLocal =  window.localStorage.getItem("userLocalToken")

                // if (accessToken && isValidToken(accessToken)) {
                if (accessToken && tokenOnLocal) {
                    setSession(accessToken);
                    // const response = await getUserFromAPI("b67f31ba-437a-4eea-aebf-a9246c48b258");
                    const response = await getUserFromAPI(tokenOnLocal);
                    const {payload} = response.data;
                

                    // const response = await axios.get(`${BASE_URL}/api/auth/me`);

                    dispatch({
                        type: INITIALIZE,
                        payload: {
                            isAuthenticated: true,
                            user: payload,
                        },
                    });
                
                }
                else {
                    dispatch({
                        type: INITIALIZE,
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    });
                }
            } catch (err) {
                dispatch({
                    type: INITIALIZE,
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        };

        initialize().catch(console.error);

        
        // getUser(userToken)
    }, []);

    const signIn = async (email, password) => {
        const data = {
            email,
            password
        }

        const response = await axios.post(`${BASE_URL}/api/auth/signin`, data);
        



        const {payload: {accessToken, user}} = response.data;

        setSession(accessToken);
        dispatch({
            type: SIGN_IN,
            payload: {
                user,
            },
        });
    };

    const signOut = async () => {
        setSession(null);
        dispatch({type: SIGN_OUT});
    };

    const signUp = async (name, email, password, agencyName, invitationToken, licensedAgent) => {
        let response;
        if (invitationToken) {
            response = await axios.post(`${BASE_URL}/api/auth/invitation/signup`, {
                name,
                password,
                invitationToken,
                licensedAgent
            });
        } else {
            response = await axios.post(`${BASE_URL}/api/auth/signup`, {
                email,
                password,
                agencyName,
                name,
                licensedAgent
            });
        }

        const {user} = response.data;
        // const {accessToken, user} = response.data;
        // window.localStorage.setItem("accessToken", accessToken);

        dispatch({
            type: SIGN_UP,
            payload: {
                user,
            },
        });
    };

    const resetPassword = (email) => console.log(email);

    return (
        <AuthContext.Provider
            value={{
                state,
                ...state,
                dispatch,
                method: "jwt",
                signIn,
                signOut,
                signUp,
                resetPassword,

            }}
        >
            {children}
        </AuthContext.Provider>
    );
}





export {AuthContext, AuthProvider, loggedInUser};
