import axios from "axios";
import {BASE_URL, token} from "../constants";

const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${token}`
};

const params = {
    userType: "ADMIN",
    userId: 57
}

export const getAgents = () => {
    return axios.get(`${BASE_URL}/support-ticket/agents/all`);
};


export const getTicketCounts = (data) => {
    return axios.get(`${BASE_URL}/support-ticket/ticket-counter?${data}`);
};

export const createTicket = (data, config) => {
    return axios.post(`${BASE_URL}/support-ticket/create`, data);
};

export const getAllTickets = (filters) => {
    return axios.get(`${BASE_URL}/support-ticket/ticket/all?${filters}`);
};

// ====== Category APIs ========
export const getAllCategories = () => {
    return axios.get(`${BASE_URL}/support-ticket/category/get/all`);
}
export const createCategory = (data, config) => {
    return axios.post(`${BASE_URL}/support-ticket/category/create`, data);
};

export const getSingleCategory = (categoryId) => {
    return axios.get(`${BASE_URL}/support-ticket/category/get/${categoryId}`);
};

export const updateCategory = (data) => {
    // console.log(data)
    return axios.put(`${BASE_URL}/support-ticket/category/update`, data);
};

export const deleteCategory = (categoryId) => {
    return axios.delete(`${BASE_URL}/support-ticket/category/delete/${categoryId}`);
}
// ====== end of Category APIs ========

// ====== Message APIs =============
export const getAllMessages = (filters) => {
    return axios.get(`${BASE_URL}/support-ticket/message/all?${filters}`);
};
export const createMessage = (data) => {
    return axios.post(`${BASE_URL}/support-ticket/message/create`, data);
};
export const uploadFile = (data) => {
    return axios.post(`${BASE_URL}/support-ticket/file/upload`, data);
};
// ===== end Of Message API ======

export const updateTicket = (data) => {
    return axios.put(`${BASE_URL}/support-ticket/update`, data);
};



export const getUserFromAPI = (token) => {
    return axios.get(`${BASE_URL}/user/info/session/${token}`);
}
